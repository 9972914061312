import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
  Typography,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import PreOrderDownloadFormStyle from "./PreOrderDownloadFormStyle";
import { Box } from "@mui/system";
import axios from "axios";

import "./TextFiledStyle.css";
import EventListData from "../../Ui-Components/EventSelectBox/EventListData";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import { useSnackbar } from "notistack";
import { ApiManager } from "../../../ApiClient";
import { Try } from "@mui/icons-material";
//Data

//phone_number validation
const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{10,})/;

//validation schema
let validationSchema = Yup.object().shape({
  race: Yup.string().required("Required"),
  orderID: Yup.string().required("Required"),
  bibNo: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone_number: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .matches(lengthRegEx, "Must contain 10 Digits!")
    .required("Required!"),
});

const PreOrderDownloadForm = ({ selectedEvent, setOpenCFModal, goToDetails,orderSelected }) => {
  const initialValues = {
    form_name: "PreOrder Download Form",
    race: selectedEvent.event_name,
    orderID: selectedEvent?.order_ref || "",
    bibNo: "",
    email: selectedEvent?.email || "",
    phone_number: selectedEvent?.phone || "",
  };

  const form = useRef();

  const [state, setState] = React.useState({
    name: "",
  });
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (values, formicHelpers) => {
    setIsLoading(true);
    const response = await axios.post(ApiManager.generatePreorderLink(), {
      orderId: values.orderID,
      orderEmail: values.email,
      orderBibNumber: values.bibNo,
      mobilenum: values.phone_number,
    });
    setIsLoading(false);
    if (response.data.StatusCode === 200) {
      if (response.data.result && response.data.result.length === 0) {
        snack.enqueueSnackbar({
          variant: "warning",
          message: response.data.Message,
        });
      } else {
        formicHelpers.resetForm();
        setTimeout(() => {
          setOpenCFModal(false);
        }, 1000);
        snack.enqueueSnackbar({
          variant: "success",
          message: response.data.Message,
        });
        if(selectedEvent?.order_ref){
          goToDetails(
            orderSelected.price,
            orderSelected.payment_id,
            values.bibNo,
            orderSelected.order_ref,
            "false",
            orderSelected.status,
            orderSelected.name
          )
        }
      }
    } else {
      snack.enqueueSnackbar({
        variant: "warning",
        message: response.data.Message,
      });
    }
  };
  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const NotesData = [
    {
      id: 0,
      note: "Please ensure that you are entering correct details.",
    },
    {
      id: 1,
      note: "Mapping once submitted is irreversible.",
    },
    {
      id: 2,
      note: "No correction is allowed after an Order ID is mapped to a bib of your choice.",
    },
  ];

  return (
    <>
    <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
    <Box sx={PreOrderDownloadFormStyle.CompWrapper}>
      <Typography
        sx={{ ...PreOrderDownloadFormStyle.formTitle, mt: 2, ml: 1, mb: 0 }}
      >
        {ConstantHelper.InquireNowTitle}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, values, handleChange, handleBlur }) => {
          return (
            <Form ref={form}>
              <CardContent
                style={PreOrderDownloadFormStyle.fromContentWrapper}
                sx={{
                  pr: {
                    xs: 0,
                    md: "auto",
                  },
                  p: 0,
                }}
              >
                <input
                  name="form_name"
                  value={values.form_name}
                  hidden
                  style={{ display: "none" }}
                />

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 14,
                      fontWeight: 900,
                    }}
                  >
                    Select Race
                  </Typography>
                  <label className="input">
                    <input
                      className="input__field"
                      name="orderID"
                      value={values.race}
                      onChange={handleChange}
                      type="text"
                      placeholder=" "
                      disabled={true}
                      style={{
                        backgroundColor: ColorHelper.disabledColor,
                        color: ColorHelper.disabledTextColor,
                        border: `1px solid ${ColorHelper.disabledTextColor}`,
                      }}
                    />
                  </label>
                </Grid>

                <Grid item container spacing={1} justify="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ width: "100%", pt: 2 }}>
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fBold,
                          fontSize: 14,
                          fontWeight: 900,
                          mb: -0.5,
                        }}
                      >
                        Enter Order ID
                      </Typography>
                      <label className="input">
                        <input
                          className="input__field"
                          name="orderID"
                          value={values.orderID}
                          onChange={handleChange}
                          type="text"
                          placeholder=" "
                          disabled={selectedEvent?.order_ref && true}
                          style={selectedEvent?.order_ref && {
                            backgroundColor: ColorHelper.disabledColor,
                            color: ColorHelper.disabledTextColor,
                            border: `1px solid ${ColorHelper.disabledTextColor}`,
                          }
                          }
                        />
                      </label>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ width: "100%", pt: 1 }}>
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fBold,
                          fontSize: 14,
                          fontWeight: 900,
                          mb: -0.5,
                        }}
                      >
                        Enter Your Email ID
                      </Typography>
                      <label className="input">
                        <input
                          className="input__field"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          type="text"
                          placeholder=" "
                          disabled={selectedEvent?.email && true}
                          style={selectedEvent?.email && {
                            backgroundColor: ColorHelper.disabledColor,
                            color: ColorHelper.disabledTextColor,
                            border: `1px solid ${ColorHelper.disabledTextColor}`,
                          }}
                        />
                      </label>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    container
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box sx={{ width: "100%", pt: 1 }}>
                        <Typography
                          sx={{
                            fontFamily: FontHelper.fBold,
                            fontSize: 14,
                            fontWeight: 900,
                            mb: -0.5,
                          }}
                        >
                          Mobile Number
                        </Typography>
                        <label className="input">
                          <input
                            className="input__field"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            type="text"
                            placeholder=" "
                            id="phone_number"
                            disabled={selectedEvent?.phone && true}
                            style={selectedEvent?.phone && {
                              backgroundColor: ColorHelper.disabledColor,
                              color: ColorHelper.disabledTextColor,
                              border: `1px solid ${ColorHelper.disabledTextColor}`,
                            }}
                          />
                        </label>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ mb: 2, width: "100%", pt: 1 }}>
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fBold,
                          fontSize: 14,
                          fontWeight: 900,
                          mb: -0.5,
                        }}
                      >
                        Enter Your Bib Number
                      </Typography>
                      <label className="input">
                        <input
                          className="input__field"
                          name="bibNo"
                          value={values.bibNo}
                          onChange={handleChange}
                          type="text"
                          placeholder=" "
                        />
                      </label>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions
                sx={{
                  p: 0,
                  m: 0,
                }}
              >
                <Button
                  disableripple="true"
                  disabled={!dirty || !isValid}
                  type="Submit"
                  // onClick={onSubmit}
                  sx={PreOrderDownloadFormStyle.btnContainer}
                >
                  <FilledButton
                    btnText={ConstantHelper.submitBtn}
                    color={ColorHelper.WhiteColor}
                    bgColor={
                      !dirty || !isValid ? "gray" : ColorHelper.PrimaryColor
                    }
                    fullWidth={true}
                    sx={{
                      width: "100%",
                      p: 0,
                      m: 0,
                      fontFamily: FontHelper.fBold,
                    }}
                  />
                </Button>
              </CardActions>
            </Form>
          );
        }}
      </Formik>

      <Box sx={{ mt: 0, px: 2 }}>
        <Typography
          sx={{
            fontFamily: FontHelper.fBold,
            fontSize: 16,
            color: ColorHelper.DarkGrey4,
          }}
        >
          {ConstantHelper.NotesTitle}
        </Typography>
        {NotesData.map((item) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src={IconHelper.DotIcon}
              style={{ maxWidth: "3px", aspectRatio: "1/1" }}
            />
            <Typography
              sx={{
                pl: 2,
                fontFamily: FontHelper.fMedium,
                fontSize: 12,
                color: ColorHelper.DarkGrey4,
              }}
            >
              {item.note}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
    </>
  );
};

export default PreOrderDownloadForm;
