import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Pagination, Tooltip } from "@mui/material";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PreOrderDownloadModal from "../Modals/PreOrderDownloadForm/PreOrderDownloadModal";
import ColorHelper from "../../Helpers/ColorHelper";
import Button from "@mui/material/Button";
import FontHelper from "../../Helpers/FontHelper";
import "../../Pages/table.css";
import { useSelector } from "react-redux";

export const OrderHistoryList = ({ goToDetails, orders }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openCFModal, setOpenCFModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [orderSelected , setOrderSelected] = useState({})
  const state = useSelector((state) => state.shop);
  // console.log("orders", orders);

  const handleGenerateLink = (row) => {
    console.log();
    setOrderSelected(row);
    setSelectedEvent({event_name:row.name , email:row.email , phone:row.phone , order_ref:row.order_ref})
    setOpenCFModal(true)
  }
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            px: { xs: 4, md: 10 },
            py: 4,
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    Sl. No.{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        ":hover": { cursor: "pointer" },
                        color: "#3F0E77",
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    Race{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    Order Ref#{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    BIB ID{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    Photos{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    Order Value{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    GST{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    Payment ID{" "}
                    <SwapVertIcon
                      sx={{
                        fontSize: "12px",
                        color: "#3F0E77",
                        ":hover": { cursor: "pointer" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">Status </TableCell>
                </TableRow>
              </TableHead>

              {orders && orders.length > 0 ? (
                <TableBody>
                  {orders
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row.index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            let isPending =
                              row.status == "Downloaded" || row.status == "Paid";
                            goToDetails(
                              row.price,
                              row.payment_id,
                              row.bib_id,
                              row.order_ref,
                              isPending,
                              row.status,
                              row.name
                            );
                          }}
                          align="center"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: ColorHelper.IntenseBlue,
                            "&:hover": {
                              color: ColorHelper.PinkColor,
                            },
                          }}
                          onClick={() => {
                            let isPending =
                              row.status == "Downloaded" || row.status == "Paid";
                            goToDetails(
                              row.price,
                              row.payment_id,
                              row.bib_id,
                              row.order_ref,
                              isPending,
                              row.status,
                              row.name
                            );
                          }}
                          align="center"
                        >
                          {row.order_ref}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            let isPending =
                              row.status == "Downloaded" || row.status == "Paid";

                            goToDetails(
                              row.price,
                              row.payment_id,
                              row.bib_id,
                              row.order_ref,
                              isPending,
                              row.status,
                              row.name
                            );
                          }}
                          align="center"
                        >
                          {row.bib_id}
                        </TableCell>
                        <TableCell align="center">{row.photos_count}</TableCell>
                        <TableCell align="center">{row.price}</TableCell>
                        <TableCell align="center">{row.gst}</TableCell>
                        <TableCell align="center">{row.payment_id}</TableCell>
                        <TableCell align="center">
                          {row.status == "Downloaded" ? (
                            <Button
                              disableripple={true}
                              onClick={() => {
                                let isPending =
                                  row.status == "Downloaded" ||
                                  row.status == "Paid";
                                goToDetails(
                                  row.price,
                                  row.payment_id,
                                  row.bib_id,
                                  row.order_ref,
                                  isPending,
                                  row.status,
                                  row.name
                                );
                              }}
                              sx={{
                                fontFamily: FontHelper.fMedium,
                                fontSize: 12,
                                color: "green",
                                backgroundColor: "#D1FADF",
                                width: "100px",
                                px: "15px",
                                py: "10px",
                                borderRadius: "3px",
                                alignItems: "center",
                                textTransform: "capitalize",
                                "&:hover": {
                                  backgroundColor: "green",
                                  color: "#D1FADF",
                                },
                              }}
                            >
                              Downloaded
                            </Button>
                          ) : null}
                          {row.status == "Cancelled" ? (
                            <Box
                              disableripple={true}
                              sx={{
                                fontFamily: FontHelper.fMedium,
                                fontSize: 12,
                                color: "#ED2B2A",
                                backgroundColor: "#FA9884",
                                width: "100px",
                                px: "15px",
                                py: "10px",
                                borderRadius: "3px",
                                textTransform: "capitalize",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Cancelled
                            </Box>
                          ) : null}
                          {row.status == "Pending" ? (
                            <Button
                              disableripple={true}
                              onClick={() => {
                                let isPending =
                                  row.status == "Downloaded" ||
                                  row.status == "Paid";
                                goToDetails(
                                  row.price,
                                  row.payment_id,
                                  row.bib_id,
                                  row.order_ref,
                                  isPending,
                                  row.status,
                                  row.name
                                );
                              }}
                              sx={{
                                fontFamily: FontHelper.fMedium,
                                fontSize: 12,
                                color: " #DC6803",
                                backgroundColor: "#FEF0C7",
                                width: "100px",
                                px: "15px",
                                py: "10px",
                                borderRadius: "3px",
                                alignItems: "center",
                                textTransform: "capitalize",
                                "&:hover": {
                                  backgroundColor: "#DC6803",
                                  color: "#FEF0C7",
                                },
                              }}
                            >
                              Retry
                            </Button>
                          ) : null}
                          {row.status == "Paid" && (
                            row.bib_id != "prebook" ?
                              <Button
                                disableripple={true}
                                onClick={() => {
                                  let isPending =
                                    row.status == "Downloaded" ||
                                    row.status == "Paid";
                                  goToDetails(
                                    row.price,
                                    row.payment_id,
                                    row.bib_id,
                                    row.order_ref,
                                    isPending,
                                    row.status,
                                    row.name
                                  );
                                }}
                                sx={{
                                  fontFamily: FontHelper.fMedium,
                                  fontSize: 12,
                                  color: "green",
                                  backgroundColor: "#D1FADF",
                                  width: "100px",
                                  px: "15px",
                                  py: "10px",
                                  borderRadius: "3px",
                                  alignItems: "center",
                                  textTransform: "capitalize",
                                  "&:hover": {
                                    backgroundColor: "green",
                                    color: "#D1FADF",
                                  },
                                }}
                              >
                                Paid
                              </Button>
                              :
                              <Tooltip
                                title="Generate download link"
                                arrow
                                placement="top"
                                sx={{
                                  tooltip: {
                                    backgroundColor: "#333",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontFamily: FontHelper.fMedium,
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)"
                                  },
                                  arrow: {
                                    color: "#333"
                                  }
                                }}
                              >
                                <Button
                                  disableripple={true}
                                  onClick={() => { handleGenerateLink(row) }}
                                  sx={{
                                    fontFamily: FontHelper.fMedium,
                                    fontSize: 12,
                                    color: "green",
                                    backgroundColor: "#D1FADF",
                                    width: "100px",
                                    px: "15px",
                                    py: "10px",
                                    borderRadius: "3px",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      backgroundColor: "green",
                                      color: "#D1FADF",
                                    },
                                  }}
                                >
                                  Generate link
                                </Button>
                              </Tooltip>
                          )
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableCell colSpan={10} align="center">
                    No matching records found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        {orders.length > rowsPerPage && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              mb: "15px",
            }}
          >
            <Pagination
              count={Math.ceil(orders?.length / rowsPerPage)}
              page={page + 1}
              color={"primary"}
              onChange={(event, value) => {
                setPage(value - 1);
              }}
            />
          </Box>
        )}
      </Box>
      {<PreOrderDownloadModal
        goToDetails={goToDetails}
        open={openCFModal}
        selectedEvent={selectedEvent}
        onClose={() => setOpenCFModal(false)}
        setOpenCFModal={setOpenCFModal}
        orderSelected={orderSelected}
      />}

    </>
  );
};
