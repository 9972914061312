import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

// Helpers
import IconHelper from "../../../Helpers/IconHelper";

// Custom Styles
// import ShowBanner3dStyle from "../../ShowBanner3dStyle";
import PreOrderDownloadModalStyle from "./PreOrderDownloadModalStyle";
import PreOrderDownloadForm from "./PreOrderDownloadForm";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import "./TextFiledStyle.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

export default function PreOrderDownloadModal(props) {
  const closeDialog = () => {
    props?.onClose();
  };

  return (
    <div style={{ position: "relative" }}>
      <Dialog
        TransitionComponent={Transition}
        open={props?.open}
        onClose={closeDialog}
        className="modalDiv"
      >
        <Box>
          <Box sx={PreOrderDownloadModalStyle.modalWrapper}>
            <Box sx={PreOrderDownloadModalStyle.modalHeader}>
              <Typography sx={PreOrderDownloadModalStyle.ModalTitle}>
                {ConstantHelper.preOrderModalTitle}
              </Typography>
              <Button onClick={closeDialog} className="cf-close">
                <IconHelper.CloseIcon
                  color={ColorHelper.WhiteColor}
                  bgcolor={ColorHelper.NavyBlue}
                />
              </Button>
            </Box>
            <Box sx={PreOrderDownloadModalStyle.modalContent}>
              <Box sx={PreOrderDownloadModalStyle.main}>
                <PreOrderDownloadForm
                  selectedEvent={props.selectedEvent}
                  setOpenCFModal={props?.setOpenCFModal}
                  goToDetails={props.goToDetails}
                  orderSelected={props.orderSelected}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
